import React from "react";
import Logo from '../assets/logotipo-extendido.png';
import Isotipo from "../assets/Isotipo.png";
import { CgMenu } from "react-icons/cg";
import { CgClose } from "react-icons/cg";
import { animateScroll as scroll } from 'react-scroll';
import "./Navbar.css";
const Navbar = () => {
  const [toggleMenu, setToggleMenu] = React.useState(false);

  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 500, //ms
      smooth: "easeInOutQuart", //suavizado
    })
  }
  return (
    <header className="sticky top-0 z-10">
    <nav className=" bg-white py-4 px-6 flex justify-between items-center flex-row-reverse">
      <div className="app__navbar-logo">
        <img onClick={scrollToTop} src={Logo} alt="app logo" className="w-48 h-auto cursor-pointer" />
      </div>
      <ul className="app__navbar-links">
        <li className="p__opensans ml-4">
          <a href="#nosotros" className="text-orange-500 hover:text-green-800">Nosotros</a>
        </li>
        <li className="p__opensans ml-4">
          <a href="#proyectos" className="text-orange-500 hover:text-green-800">Proyectos</a>
        </li>
        <li className="p__opensans ml-4">
          <a href="#servicios" className="text-orange-500 hover:text-green-800">Servicios</a>
        </li>
        <li className="p__opensans ml-4">
          <a href="#equipo" className="text-orange-500 hover:text-green-800">Equipo</a>
        </li>
        <li className="p__opensans ml-4">
          <a href="#contacto" className="text-orange-500 hover:text-green-800">Contacto</a>
        </li>
      </ul>

      <div className="app__navbar-smallscreen">
        <CgMenu
          color="black"
          fontSize={27}
          onClick={() => setToggleMenu(true)}
        />
        {toggleMenu && (
            <div className="app__navbar-smallscreen_overlay flex__center slide-button ">
              <CgClose
                color="white"  
                fontSize={27}
                className="overlay__close"
                onClick={() => setToggleMenu(false)}
              />
        
        <ul className="app__navbar-smallscreen-links ">
        <CgClose
        color="white"  
        fontSize={27}
        className="overlay__close mr-1"
        onClick={() => setToggleMenu(false)}
        />
        <div className="mb-20">
            <img src={Isotipo} alt="isotipo-ecoConstructores" className="w-20 h-20"/>
            </div>
            <div className="w-full">
              <li className="p__opensans">
                <a href="#nosotros" className="text-white hover:text-orange-500" onClick={() => setToggleMenu(false)}>Nosotros</a>
              </li>
              <li className="p__opensans">
                <a href="#proyectos" className="text-white hover:text-orange-500" onClick={() => setToggleMenu(false)}>Proyectos</a>
              </li>
              <li className="p__opensans">
                <a href="#servicios" className="text-white hover:text-orange-500" onClick={() => setToggleMenu(false)}>Servicios</a>
              </li>
              <li className="p__opensans">
                <a href="#equipo" className="text-white hover:text-orange-500" onClick={() => setToggleMenu(false)}>Equipo</a>
              </li>
              <li className="p__opensans">
                <a href="#contacto" className="text-white hover:text-orange-500" onClick={() => setToggleMenu(false)}>Contacto</a>
              </li>
              </div>
            </ul>
            </div>
        )}
      </div>
    </nav>
    </header>
  );
};

export default Navbar;