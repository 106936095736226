import React, { Component } from "react";
import Carousel from "nuka-carousel";
import "./backgroundPage.css";
import "./projects.css";
import la5 from "../assets/linea-amarilla/la-5.jpg";
import andoas from "../assets/proyecto-andoas/proyecto-andoas.JPG";
import tsr from "../assets/tunel-santa-rosa/tsr.JPG";
import fuerabambas from "../assets/expGG/fuerabambas.jpg";
import rioTinto from "../assets/la-granja/la-granja-rio-tinto.JPG";
import oficinas from "../assets//villa-rdl/Oficinas-villa.jpg";
import puertoSalaverry from "../assets/expGG/puerto-salaverry5.jpg";
import peaje from "../assets/expGG/peaje-punta.jpg";
import rutasLima from "../assets/expGG/rutas-lima3.jpg";
import lineaAmarilla from "../assets/linea-amarilla/linea-amarilla.jpg";
import pistaAterrizaje from "../assets/aeropuerto/pista-aeropuerto.jpg";
import pistaAterrizaje2 from "../assets/aeropuerto/pista-aeropuerto2.jpg";
import asfaltadoPlaya from "../assets/clubesPlaya.jpeg";
import aeropuertoAyacucho from "../assets/aeropuerto/aeropuerto-ayacucho.JPG";
import phone from "../assets/phone.png";
import leftArrow from "../assets/left-arrow.png";
import rightArrow from "../assets/right-arrow.png";
import sedapal from "../assets/expGG/sedapal.jpg";

export default class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
    };
  }
  handleMouseEnter = () => {
    this.setState({ isHovered: true });
  };

  handleMouseLeave = () => {
    this.setState({ isHovered: false });
  };

  render() {
    const images = [
      {
        src: la5,
        alt: "Linea Amarilla",
        title: "Rutas de Lima- ARUP",
      },
      {
        src: peaje,
        alt: "peaje-Punta-Negra",
        title: "Rutas de Lima - ARUP",
      },
      {
        src: asfaltadoPlaya,
        alt: "playa-honda",
        title: "Asfaltado Playa la Honda, la Quipa y Pelícanos",
      },
      {
        src: rutasLima,
        alt: "rutas-lima",
        title: "Rutas de Lima - ARUP",
      },
      {
        src: lineaAmarilla,
        alt: "Lima Expresa",
        title: "Lima Expresa",
      },
      {
        src: oficinas,
        alt: "Oficinas",
        title: "Rutas de Lima  - ARUP",
      },
      {
        src: puertoSalaverry,
        alt: "Puerto-Salaverry",
        title: "Puerto de Salaverry - ARUP",
      },

      // Agrega más objetos para las demás imágenes
    ];

    const images_second_slider = [
      {
        src: fuerabambas,
        alt: "Nueva Fuerabambas",
        title: "Nueva Fuerabambas",
      },
      {
        src: tsr,
        alt: "Tunel Santa Rosa",
        title: "Tunel Santa Rosa - GyM",
      },
      {
        src: rioTinto,
        alt: "La Granja",
        title: "La Granja - Río Tinto - GyM",
      },
      {
        src: andoas,
        alt: "Andoas",
        title: "Proyecto Andoas - GyM",
      },
      {
        src: pistaAterrizaje,
        alt: "pista-aterrizaje-JorgeChavez",
        title: "Aeropuerto - Callao - TDM",
      },
      {
        src: aeropuertoAyacucho,
        alt: "aeropuerto-Ayacucho",
        title: "Aeropuerto - Ayacucho - P|C",
      },
      {
        src: pistaAterrizaje2,
        alt: "pista-aterrizaje-JorgeChavez",
        title: "Aeropuerto - Callao - TDM",
      },
      {
        src: sedapal,
        alt: "saneamiento - sedapal",
        title: "Saneamiento - Sedapal",
      }
    ];

    return (
      <div>
        {/* Galería de proyectos */}
        <section
          id="proyectos"
          className="projects py-16 px-4 background-section"
        >
          <div className="container mx-auto text-center">
            <h1 className="text-3xl mt-4 mb-8 text-start hover:text-orange-500">
              Nuestros Proyectos
            </h1>
            <div className="flex flex-col justify-center">
              <Carousel
                wrapAround={true}
                renderCenterLeftControls={({ previousSlide }) => (
                  <button onClick={previousSlide}>
                    <img src={leftArrow} className="ml-4" alt="left-button" />
                  </button>
                )}
                renderCenterRightControls={({ nextSlide }) => (
                  <button onClick={nextSlide}>
                    <img src={rightArrow} className="mr-4" alt="right-button" />
                  </button>
                )}
                defaultControlsConfig={{
                  pagingDotsStyle: {
                    marginRight: "3px",
                    marginBottom: "15px",
                    fill: "white",
                  },
                }}
              >
                {images.map((image, index) => (
                  <div
                    key={index}
                    className="project rounded-lg overflow-hidden m-4 relative"
                  >
                    <div className="image-wrapper relative">
                      <div className="overlay flex justify-center text-center items-center">
                        <h3 className="text-xl mb-2 font-medium text-white img_title">
                          {image.title}
                        </h3>
                      </div>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="w-full h-96 object-cover"
                      />
                    </div>
                  </div>
                ))}
              </Carousel>
              <div className="container mx-auto text-center">
                <h2 className="text-3xl mt-8 mb-8 text-start hover:text-orange-500">
                  Experiencia de nuestro Gerente General
                </h2>
                <div className="flex flex-col justify-center">
                  <Carousel
                    wrapAround={true}
                    renderCenterLeftControls={({ previousSlide }) => (
                      <button onClick={previousSlide}>
                        <img
                          src={leftArrow}
                          className="ml-4"
                          alt="left-button"
                        />
                      </button>
                    )}
                    renderCenterRightControls={({ nextSlide }) => (
                      <button onClick={nextSlide}>
                        <img
                          src={rightArrow}
                          className="mr-4"
                          alt="right-button"
                        />
                      </button>
                    )}
                    defaultControlsConfig={{
                      pagingDotsStyle: {
                        marginRight: "3px",
                        marginBottom: "15px",
                        fill: "white",
                      },
                    }}
                  >
                    {images_second_slider.map((image, index) => (
                      <div
                        key={index}
                        className="project rounded-lg overflow-hidden m-4 relative"
                      >
                        <div className="image-wrapper relative">
                          <div className="overlay flex justify-center text-center items-center">
                            <h3 className="text-xl mb-2 font-medium text-white img_title">
                              {image.title}
                            </h3>
                          </div>
                          <img
                            src={image.src}
                            alt={image.alt}
                            className="w-full h-96 object-cover"
                          />
                        </div>
                      </div>
                    ))}
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Botón sticky de WhatsApp */}
        <a
          href="https://api.whatsapp.com/send?phone=945085470"
          target="_blank"
          rel="noopener noreferrer"
          className="animate-bounce whatsapp-button fixed bottom-4 right-4 z-10 flex items-center justify-center w-12 h-12 bg-green-500 hover:bg-green-600 text-white rounded-full"
        >
          <img src={phone} alt="WhatsApp" className="w-6 h-6" />
        </a>
      </div>
    );
  }
}
