import React, { Component } from 'react'
import Slider from 'react-slick';
import "../slick-carusel.css";
import "./aboutUs.css";
import "./backgroundPage.css";

export default class AboutUs extends Component {     
    render() {
        const carouselSettings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 40000,
          };
          return(
            <div>
            <section id="nosotros" className="about pb-6 pt-8 px-4 ">
            <div className="container mx-auto text-center">
              <h1 className="text-3xl mb-8 mt-16 text-start hover:text-orange-500">Nosotros</h1>
              <Slider {...carouselSettings}>
                <div className="about-slide">
                  <div className="about-slide-overlay rounded-lg shadow-none px-8 py-6 cursor-grabbing">
                    <h3 className="text-xl mb-4 text-start hover:text-green-800">¿Quiénes Somos?</h3>
                    <p className="text-gray-600 text-justify">
                    Somos una empresa con RUC:20550588901 comprometida con la filosofía de la construcción sostenible, con un alto sentido de responsabilidad social.
                    Brindamos servicios de consultorías técnicas en el rubro de la construcción en cada una de las etapas del proyecto, 
                    planeamiento, construcción y la administración contractual. Supervisamos proyectos de Construcción, EPC, EPCM. 
                    Gerenciamos de manera integral proyectos de cualquier tipo de infraestructura. Desde la selección de la empresa ejecutora, 
                    pasando por la ejecución del mismo, incluyendo la obtención de licencias ante los organismos correspondientes, hasta la 
                    entrega final y puesta en operación del proyecto, considerando la administración contractual para salvaguardar los intereses
                    de nuestros contratantes. Asimismo, participamos de JRDs y DABs, tanto a nivel nacional como internacional. 
                    Contamos con profesionales con más de 20 años de experiencia profesional y reconocida trayectoria en consultorías técnicas a 
                    nivel nacional e internacional.
                    </p>
                  </div>
                </div>
                <div className="about-slide">
                  <div className="about-slide-overlay items-center  md:max-w-3xl mx-auto  px-8 py-6 cursor-grabbing rounded-lg">
                    <h3 className="text-xl mb-4 text-start hover:text-green-800">Visión</h3>
                    <p className="text-gray-600 text-justify mb-8">
                    Ser un referente en el país, en la 
                    prestación de servicios de consultoría, 
                    supervisión y gerencia integral de 
                    proyectos, en proyectos de 
                    construcción sostenibles, con 
                    reconocimiento nacional e 
                    internacional.
                    </p>
                    <br></br>
                    <h3 className="text-xl mb-4 text-start hover:text-green-800">Misión</h3>
                    <p className="text-gray-600 text-justify">
                    Ofrecer el servicio más efciente de 
                    gestión de proyectos de construcción 
                    eco-sostenibles, cumpliendo a 
                    cabalidad tiempos y requerimientos 
                    exigidos por nuestros clientes, con los 
                    más altos estándares de seguridad y 
                    calidad.
                    </p>
                  </div>
                </div>

              </Slider>
            </div>
          </section>
            </div>
          )
    }
}
