import React, { useState, useEffect } from 'react';
import construccionEco from "../assets/tiendas-comerciales.png";
import consulting from "../assets/consulting.jpg";
import gerencia from "../assets/gerencia-py.jpg";
import consultoria from "../assets/consultoria.jpg";
import files from "../assets/files.jpg";
import LazyImage from './lazyImage';
import "./backgroundPage.css";
import 'aos/dist/aos.css';

const proyectos = [
  {
    key: 0,
    src: construccionEco,
    alt: "Servicio 1",
    title: "Construcciones eco-amigables",
    subtitle: "Elaboración y desarrollo de proyectos de construcción eco-amigables.",
  },
  {
    key: 1,
    src: consultoria,
    alt: "Servicio 2",
    title: "Consultorías",
    subtitle: "Servicios de consultoría a nivel nacional e internacional.",
  },
  {
    key: 2,
    src: gerencia,
    alt: "Servicio 3",
    title: "Gerencia integral de proyectos",
    subtitle: "Búsqueda y selección de empresas constructoras, monitoreo y supervisión de la obra. Gestión contractual y administrativa.",
  },
  {
    key: 3,
    src: files,
    alt: "Servicio 4",
    title: "Peritajes Técnicos",
    subtitle: "Servicio de Peritajes Técnicos de Parte, respecto a Liquidaciones de Obra",
  },
];

const ServiceCard = ({ src, alt, title, subtitle }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const img = new Image();
    img.src = src;

    const handleLoad = () => {
      setIsLoading(false);
    };

    const handleError = () => {
      setIsLoading(false);
    };

    img.onload = handleLoad;
    img.onerror = handleError;

    // Limpieza
    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, [src]);

  return (
    <div className="service-card bg-transparent backdrop-filter backdrop-blur-sm rounded-lg pb-4 m-4 md:w-[593.7px]">
      {isLoading ? (
        <div className="flex justify-center items-center h-48 bg-silver-200 animate-pulse">
          <div className="spinner"></div>
        </div>
      ) : (
        <LazyImage 
          src={src} 
          alt={alt} 
          className="w-full h-48 object-cover mx-auto mb-6 rounded-t-lg" 
        />
      )}
      <h3 className="text-xl mb-2 px-4">{title}</h3>
      <p className="text-gray-600 text-justify px-4">{subtitle}</p>
    </div>
  );
};

const ServicesSection = () => (
  <section id="servicios" className="services pt-14 pb-0 px-4">
    <div className="container mx-auto text-center">
      <h1 className="text-3xl mb-8 mt-4 text-start hover:text-orange-500">Nuestros Servicios</h1>
      <div className="flex flex-wrap justify-center">
        {proyectos.map(proyecto => <ServiceCard key={proyecto.key} {...proyecto} />)}
      </div>
    </div>
  </section>
);

export default ServicesSection;
