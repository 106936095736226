import React, { useEffect } from 'react'
import fotoJuan from "../assets/Juan-foto.jpeg";
import fotoAlvaro from "../assets/alvaro-recorte.png";
import "./teamMembers.css";
import AOS from 'aos';
import 'aos/dist/aos.css';

const TeamMembers = () => {
  useEffect(() => {
    AOS.init({duration: 1500,});
  }, []);
  return (
    <div>
    <section id="equipo" className="testimonials py-20 bg-green-900 px-4">
    <div className="container mx-auto text-center">
      <h1 className="text-3xl mb-8 text-white text-start hover:text-orange-500" data-aos="fade-up">Nuestro Equipo</h1>
      <div className="flex flex-wrap justify-center" data-aos="fade-down">
      <div className="team_member bg-white pr-6 rounded-lg m-4 flex items-center w-[473.02px]">
        <img src={fotoJuan} alt="team member1" className="w-40 h-auto mr-8 rounded-l-lg" />
        <div>
          <h3 className="text-green-900 font-bold">Juan Carlos del Río Rendón</h3>
          <p className="text-gray-600 text-lg">Gerente General</p>
        </div>
      </div>
      <div className="team_member bg-white pr-6 rounded-lg m-4 flex items-center">
        <img src={fotoAlvaro} alt="team member2" className="w-48 h-[205.7px] rounded-l-lg" />
        <div>
          <h3 className="text-green-900 font-bold">Álvaro Manuel del Río Cárdenas</h3>
          <p className="text-gray-600">Responsable de Logística</p>
        </div>
      </div>
    </div>
    </div>
  </section>
    </div>
  )
}

export default TeamMembers

/*       <div className="flex flex-wrap justify-center">
      <div className="team_member bg-white p-6 rounded-lg m-4 flex items-center">
        <img src={fotoJuan} alt="team member1" className="w-40 h-auto mr-4" />
        <div>
          <h3 className="text-green-900 font-bold">Juan Carlos del Río Rendón</h3>
          <p className="text-gray-600 text-lg">Gerente General</p>
        </div>
      </div>
      <div className="team_member bg-white p-6 rounded-lg m-4 flex items-center">
        <img src={fotoAlvaro} alt="team member2" className="w-40 h-auto mr-4" />
        <div>
          <h3 className="text-green-900 font-bold">Álvaro Manuel del Río Cárdenas</h3>
          <p className="text-gray-600 text-lg">Responsable de Logística</p>
        </div>
      </div>
    </div>     */