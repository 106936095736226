import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import 'tailwindcss/tailwind.css';
import ServicesSection from './components/ServicesSection.jsx';
import HeroSection from './components/HeroSection.jsx';
import AboutUs from './components/AboutUs.jsx';
import TeamMembers from './components/TeamMembers.jsx';
import Contact from './components/Contact.jsx';
import Projects from './components/Projects.jsx';
import Navbar from './components/Navbar';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
  <Navbar/>
  <HeroSection/>
  <AboutUs/> 
  <Projects />
  <ServicesSection />
  <TeamMembers /> 
  <Contact />
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
