import React, { useEffect } from 'react';
import heroBackground from "../assets/hero-section.png";
import ecoConsultores from "../assets/eco-consultores-bg.png";
import AOS from 'aos';
import 'aos/dist/aos.css';

const HeroSection = () => {
    useEffect(() => {
      AOS.init({duration: 1500,});
    }, []);

    return (
      <div>  
        {/* Hero Image */}
        <section id='hero-img' className="hero relative py-52">
          <div className="absolute inset-0">
            <img  
              data-aos="zoom-in"
              src={ecoConsultores}
              alt="Imagen de fondo"
              className="w-full h-full object-cover"
            />
          </div>
        </section>
      </div>
    )
}

export default HeroSection;
