import React, { useRef, useState } from 'react';
import pin from "../assets/location-pin.png";
import phone from "../assets/call.png";
import email from "../assets/email.png";
import emailjs from '@emailjs/browser';
import "./contact.css";


const Contact = () => {
  const form = useRef(null);
  const [isMessageSent, setIsMessageSent] = useState(false);
  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    message: '',
  });

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_6y07fai', 'template_349p2xb', form.current, 'dKgjydaBNU_kb6lF5') //Service ID, Template ID , form.current, Public Key
      .then((result) => {
          setIsMessageSent(true);
          
          setTimeout(() => {
            setIsMessageSent(false);
            setFormData({
              user_name: '',
              user_email: '',
              message: '',  
            });
          }, 5000);
          
        });   
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div>
    <section id="contacto" className="contact py-16 mt-0 pt-24 bg-gray-200">
    <div className="container mx-auto text-center">
      <h2 className="text-3xl my-8 text-green-900 hover:text-orange-500">¡Contáctanos!</h2>
      <p className="text-gray-600 mb-8 px-4">¿Tienes alguna pregunta o consulta? Estamos aquí para ayudarte.</p>
      <div className="flex justify-center">
      <form className="w-screen px-4" ref={form} onSubmit={sendEmail}>
      <div className="mb-4">
        <label className="block text-gray-700 text-left mb-2" htmlFor="name">
          Nombre
        </label>
        <input 
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-green-500" 
        id="name" 
        type="text" 
        name='user_name' 
        placeholder="Ingresa tu nombre"
        value={formData.user_name}
        onChange={handleChange}
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-left mb-2" htmlFor="email">
          Email
        </label>
        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-green-500" 
        id="email" 
        type="email" 
        name='user_email' 
        placeholder="Ingresa tu email"
        value={formData.user_email}
        onChange={handleChange}
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-left mb-2" htmlFor="message">
          Mensaje
        </label>
        <textarea className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-green-500" 
        id="message" 
        name='message' 
        rows="5" 
        placeholder="Ingresa tu mensaje"
        value={formData.message}
        onChange={handleChange}
        ></textarea>
      </div>
      <div className="flex justify-center">
      <button
      className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 bg-green-800 hover:bg-green-500 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      type="submit"
      value="Send"
    >
      Enviar mensaje
    </button>
      </div>
      {isMessageSent && (
        <div className="bg-green-200 text-green-800 px-4 py-2 mt-4 rounded animate-fadeUp">
          Mensaje enviado correctamente.
        </div>
      )}  
    </form>
      </div>
    </div>
    <div className="flex flex-wrap container mx-auto justify-between mt-12">
        <div className="contact-info m-4">
          <h3 className="text-xl mb-2 flex">Llámanos</h3>
          <p className="text-gray-600 flex"><img src={phone} alt='phone' className='w-4 h-4 mr-2 mt-1'/>945 085 470</p>
        </div>
        <div className="contact-info m-4">
          <h3 className="text-xl mb-2">Escríbenos</h3>
          <p className="text-gray-600 flex"><img src={email} alt='email' className='w-4 h-4 mr-2 mt-1'/>info@eco-constructores.com</p>
        </div>
        <div className="contact-info m-4">
          <h3 className="text-xl mb-2">Ubicación</h3>
          <p className="text-gray-600 flex"><img src={pin} alt='pin-location' className='w-6 h-6'/>Calle Doña Consuelo 175 Piso 3 - Los Rosales, Surco</p>
        </div>

      </div>
  </section>

 { /* Footer */}
    <footer className="bg-gray-100 py-4 px-6 text-center">
    <p className="text-gray-600">© 2024 ecoconstructores. Todos los derechos reservados.</p>
    </footer>
    </div>  
  )
}

export default Contact